import keyboard from 'vue-keyboard';

export default {
    data () {
        return {
            field: '',
        }
    },
    components: {
        keyboard,
    },
    methods: {
        setField(field) {
            this.field = field
        },

        changed(value) {
            if (value.length > 0)
                this[this.field] += value
            else {
                this[this.field] = this[this.field].slice(0, -1)
            }
        },
    }
}
