<template>

    <div class="overflow-y-auto flex flex-col h-screen" v-cloak>
        <div class="flex-grow p-8 text-2xl flex flex-col h-full-no-buttons">
            <div class="flex justify-between items-start mb-8">
                <h2 class="text-4xl font-bold flex justify-start items-center move-table-title">
                    <div class="mr-4">{{$t('translations.views.pos.table.tables-virtual.title')}}</div>
                </h2>
                <a @click="close()" class="screen-close">
                    <svg class="w-14 h-14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </a>
            </div>

            <div class="flex justify-between mb-8 default-input">
                <input type="text" ref="name" placeholder="Naam *" v-model="name" name="name" class="create-client mr-8 flex-grow" />
            </div>

            <keyboard
                v-model="input"
                @custom="custom"
                @input="changed"
                :layouts="[
                    '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                    '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                ]"
                :maxlength="16"
            ></keyboard>

        </div>

        <footer class="button-bar text-xl">
            <div class="flex justify-end space-x-4">
                <a v-if="table_from" @click="close()" class="button button-danger">
                    {{$t('translations.views.pos.table.tables-virtual.close')}}
                </a>
                <button :disabled="saving || name.length < 3" @click="submit()" class="button button-filled">
                    {{$t('translations.views.pos.table.tables-virtual.confirm')}}
                    <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </button>
            </div>
        </footer>
    </div>

</template>

<script>
import axios from 'axios';

// mixins
import KeyboardHelper from '../../../mixins/keyboard.helper.js';

export default {
    name: 'TablesVirtual',
    mixins: [KeyboardHelper],
    data () {
        return {
            name: '',
            field: 'name',
        };
    },

    methods: {

        close() {
            this.$router.push({name: 'pos.tables'});
            // if (this.terminal.layout == 'mobileordering') {
            //     this.$router.push({name: 'mobilepos.table', params: {
            //         area_id: this.area_id,
            //         table_id: this.table_id,
            //     }});
            // } else {
            //     this.$router.push({name: 'pos.tables'});
            // }
        },

        // submit
        submit () {
            this.saving = true;

            axios.post('/tables/virtual/open', {
                name: this.name,
            }).then(response => {
                this.$store.dispatch('transaction/setTable', response.data.data);
                this.$router.push({ name: 'pos' });

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => this.saving = false);
        },
    },
};
</script>
